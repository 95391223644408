<template>
  <div
    class="flex w-full"
    :class="[
      data.rightSide ? 'lg:flex-row' : 'lg:flex-row-reverse',
      data.bottomMobile ? 'max-lg:flex-col' : 'max-lg:flex-col-reverse',
    ]"
    :style="{
      backgroundColor: lgAndUp
        ? data?.slides?.[0].backgroundColor?.hexa || '#F4EEE8'
        : data?.slides?.[0].backgroundColorMobile?.hexa ||
          data?.slides?.[0].backgroundColor?.hexa ||
          '#F4EEE8',
      height: lgAndUp ? data.slides?.[0].height?.desktop || '600px' : '',
    }"
  >
    <!--left part - card -->
    <div
      :style="{
        width: width,
      }"
      :class="[
        data.bottomMobile
          ? 'max-lg:pb-0 max-lg:pt-12.5'
          : 'max-lg:pb-12.5 max-lg:pt-0',
      ]"
      class="container flex justify-center items-center py-4 max-lg:!w-full lg:py-0"
    >
      <div>
        <!--
        <h3
          v-if="data?.slides?.[0]?.subTitle"
          class="pb-4 text-sm font-medium tracking-tighter_l"
        >
          {{ data?.slides?.[0].subTitle }}
        </h3>
        <div
          v-if="data?.slides?.[0]?.title"
          class="content whitespace-break-spaces break-words text-[36px] font-normal leading-[42px] text-primary max-lg:text-center lg:text-[40px] lg:leading-[46px]"
          v-html="data?.slides?.[0].title"
        />
        <hr
          v-if="data?.slides?.[0]?.title"
          class="my-4 h-px w-[100px] border-none bg-primary max-lg:mx-auto"
        />
        -->
        <div
          class="content break-words"
          v-html="data?.slides?.[0].text"
        />
        <!--
        <UiButton
          v-if="data?.slides?.[0].buttonUrl && data?.slides?.[0].buttonLabel"
          class="mt-10 h-12.5 border-primary !px-5 font-medium max-lg:w-full"
          :to="localePath(data?.slides?.[0].buttonUrl ?? '')"
          color="primary"
          variant="outlined"
        >
          <span>{{ data?.slides?.[0].buttonLabel }}</span>
        </UiButton>
        -->
      </div>
    </div>
    <!--right part - Image -->
    <div
      class="w-full lg:w-1/2"
      :class="[
        data.bottomMobile
          ? 'max-lg:pt-12.5'
          : 'max-lg:pb-12.5',
      ]"
      :style="{
        height:
          lgAndDown && data.slides?.[0].height?.mobile
            ? data.slides?.[0].height?.mobile
            : '100%',
      }"
    >
      <video
        v-if="data?.file?.media_type?.includes(assetsTypes.video)"
        class="h-full w-full object-cover"
        :src="
          lgAndDown ? data?.fileMobile?.url || data?.file?.url : data?.file?.url
        "
        autoplay
        loop
        muted
        playsinline
      />
      <UiLazyImg
        v-else
        class="h-full w-full"
        :src="data?.file?.url || ''"
        :src-mobile="data?.fileMobile?.url || ''"
        :alt="
          data?.file?.alt ||
          removeHTMLTagFromString(data?.slides?.[0].title || '') ||
          'Image'
        "
        cover
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { assetsTypes, BlockSliderContent } from "ecom-types";

const props = defineProps<{ data: BlockSliderContent }>();

const localePath = useLocalePathPolyfill();

const { mdAndDown, mdAndUp, lgAndUp, lgAndDown } = useAppBreakpoints();

const width = computed(() => {
  const widthData = props.data?.slides?.[0].width || "50%";
  if (widthData.endsWith("%")) {
    return `calc(var(--container-width) * ${parseFloat(widthData) / 100})`;
  }
  return widthData;
});
</script>

<style scoped>
.content:deep(.button) {
  @apply flex max-w-fit items-center justify-center bg-primary px-[25px] py-4 text-sm uppercase text-white outline-0 transition-all hover:bg-neutral-black-800;
}
.content:deep(.button.outline) {
  @apply border !border-primary !bg-transparent !text-primary hover:!bg-primary hover:!text-white;
}
</style>
